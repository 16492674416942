* {
  box-sizing: border-box;
  outline: none;
}
html {
  font-size: 62.5%;
}
#sidebar .nav {
  height: 50px;
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Staatliches', cursive;
  font-size: 3rem;
  cursor: pointer;
  background: #f2f2f2;
  box-shadow: 6px 6px 10px -3px rgba(0, 0, 0, 0.35);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  text-decoration: none;
  color: #1a1a1a;
}
#sidebar .nav-before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: -6px;
}
#craft .cutout {
  box-shadow: inset 5px 5px 8px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}
#craft .sticker {
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  background: #f2f2f2;
  border-radius: 2px;
}
#craft .top-layer {
  -webkit-transform: translateZ(0.02px) scale(0.98);
  transform: translateZ(0.02px) scale(0.98);
  z-index: 1;
}
#craft .middle-layer {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
#craft .lower-layer {
  -webkit-transform: translateZ(-0.08px) scale(1.08);
  transform: translateZ(-0.08px) scale(1.08);
  z-index: -1;
}
#app {
  font-family: 'Raleway', sans-serif;
  display: flex;
  scroll-behavior: smooth;
  perspective: 1px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f2f2f2;
}
#app .rightbar {
  display: flex;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  background: #f2f2f2;
  height: 100vh;
  position: sticky;
  top: 0;
}
.sidebar-container {
  z-index: 1000;
  position: sticky;
  top: 0;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}
.sidebar-container .sidebar {
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.35);
  background: #f2f2f2;
  height: 100vh;
  min-width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media (min-width: 1800px) {
  .sidebar-container .sidebar {
    align-items: flex-end;
    padding-right: 20px;
  }
}
@media (max-width: 600px) {
  .sidebar-container .sidebar {
    min-width: 100px;
    width: 100px;
  }
}
.sidebar-container .sidebar .my-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Staatliches', cursive;
  font-size: 4rem;
  margin-top: 40px;
  width: 150px;
  min-height: 120px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  background: #f2f2f2;
  text-decoration: none;
  color: #1a1a1a;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 600px) {
  .sidebar-container .sidebar .my-name {
    transform: rotate(-90deg);
    font-size: 3.3rem;
    margin: 100px 0 100px;
    width: 225px;
    min-height: 60px;
    box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.2);
  }
}
.sidebar-container .sidebar .quick-contacts {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  padding-bottom: 50px;
  z-index: 1;
  pointer-events: none;
}
@media (min-width: 1800px) {
  .sidebar-container .sidebar .quick-contacts {
    align-items: flex-end;
    padding-right: 50px;
  }
}
.sidebar-container .sidebar .quick-contacts a {
  margin-bottom: 20px;
}
.sidebar-container .sidebar .quick-contacts a img {
  width: 50px;
  height: 50px;
  padding: 10px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background: #f2f2f2;
  cursor: pointer;
  pointer-events: auto;
}
.sidebar-container .sidebar-extended-shadow {
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.15);
  height: 100vh;
  width: 180px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -10;
}
@media (max-width: 600px) {
  .sidebar-container .sidebar-extended-shadow {
    width: 100px;
  }
}
.sidebar-container .toggle-nav {
  height: 50px;
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Staatliches', cursive;
  font-size: 3rem;
  cursor: pointer;
  background: #f2f2f2;
  box-shadow: 6px 6px 10px -3px rgba(0, 0, 0, 0.35);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  text-decoration: none;
  color: #1a1a1a;
  margin-top: -100vh;
  margin-right: -197px;
  width: 198px;
  padding-right: 11px;
  z-index: 1;
  display: none;
}
@media (max-width: 1200px) {
  .sidebar-container .toggle-nav {
    display: flex;
    justify-content: flex-end;
  }
}
.sidebar-container .toggle-nav .arrow-left {
  height: 30px;
  transform: rotate(90deg);
}
.sidebar-container .toggle-nav .arrow-right {
  height: 30px;
  transform: rotate(-90deg);
}
.sidebar-container .about {
  height: 50px;
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Staatliches', cursive;
  font-size: 3rem;
  cursor: pointer;
  background: #f2f2f2;
  box-shadow: 6px 6px 10px -3px rgba(0, 0, 0, 0.35);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  text-decoration: none;
  color: #1a1a1a;
  margin-top: -80vh;
  margin-right: -99px;
  width: 100px;
  z-index: -1;
}
.sidebar-container .about::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: -6px;
  border-left: 6px solid #0099dd;
}
.sidebar-container .projects {
  height: 50px;
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Staatliches', cursive;
  font-size: 3rem;
  cursor: pointer;
  background: #f2f2f2;
  box-shadow: 6px 6px 10px -3px rgba(0, 0, 0, 0.35);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  text-decoration: none;
  color: #1a1a1a;
  margin-top: -70vh;
  margin-right: -139px;
  width: 140px;
  z-index: -2;
}
.sidebar-container .projects::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: -6px;
  border-left: 6px solid #d90d79;
}
.sidebar-container .resume {
  height: 50px;
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Staatliches', cursive;
  font-size: 3rem;
  cursor: pointer;
  background: #f2f2f2;
  box-shadow: 6px 6px 10px -3px rgba(0, 0, 0, 0.35);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  text-decoration: none;
  color: #1a1a1a;
  margin-top: -60vh;
  margin-right: -117px;
  width: 118px;
  z-index: -4;
}
.sidebar-container .resume::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: -6px;
  border-left: 6px solid #0099dd;
}
.sidebar-container .contact {
  height: 50px;
  position: absolute;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Staatliches', cursive;
  font-size: 3rem;
  cursor: pointer;
  background: #f2f2f2;
  box-shadow: 6px 6px 10px -3px rgba(0, 0, 0, 0.35);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  text-decoration: none;
  color: #1a1a1a;
  margin-top: -50vh;
  margin-right: -124px;
  width: 125px;
  z-index: -5;
}
.sidebar-container .contact::before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: -6px;
  border-left: 6px solid #d90d79;
}
.sidebar-container .active {
  z-index: 1;
}
.sidebar-container .out-of-focus {
  display: none;
}
@media (max-width: 1200px) {
  .sidebar-container .out-of-focus {
    width: 1600px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -10;
    position: absolute;
    top: 0;
    display: flex;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
}
.sidebar-container .in-focus {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0);
}
.hidden {
  margin-left: 0;
}
@media (max-width: 1200px) {
  .hidden {
    margin-left: -328px;
    margin-right: 328px;
  }
}
@media (max-width: 600px) {
  .hidden {
    margin-left: -248px;
    margin-right: 248px;
  }
}
.home-container {
  max-width: 1600px;
  width: 100%;
  height: 100vh;
  margin-left: -184px;
  padding-left: 184px;
}
@media (max-width: 1200px) {
  .home-container {
    padding-left: 0;
  }
}
@media (max-width: 600px) {
  .home-container {
    margin-left: -101px;
  }
}
#home {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}
@media (max-width: 600px) {
  #home {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
#home .bars-container {
  height: 300px;
  min-width: 1600px;
  overflow: hidden;
  margin-left: -15px;
}
@media (max-width: 1200px) {
  #home .bars-container {
    height: 240px;
    margin-left: -7px;
  }
}
@media (max-width: 900px) {
  #home .bars-container {
    margin-left: -12px;
  }
}
@media (max-width: 600px) {
  #home .bars-container {
    height: 450px;
    margin-left: -13px;
  }
}
#home .bars-container .base {
  margin: -100px 0 0 -200px;
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 1200px) {
  #home .bars-container .base {
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
@media (max-width: 900px) {
  #home .bars-container .base {
    margin: -70px 0 0 -200px;
  }
}
#home .parallax__layer--me {
  -webkit-transform: translateZ(-0.01px) scale(1.01);
  transform: translateZ(-0.01px) scale(1.01);
  margin-left: 150px;
  position: absolute;
  z-index: -1;
}
@media (max-width: 1200px) {
  #home .parallax__layer--me {
    -webkit-transform: translateZ(-0.008px) scale(0.808);
    transform: translateZ(-0.008px) scale(0.808);
    margin-left: 50px;
  }
}
@media (max-width: 900px) {
  #home .parallax__layer--me {
    margin-left: 20px;
  }
}
@media (max-width: 600px) {
  #home .parallax__layer--me {
    margin: -100px 0 0 30px;
  }
}
#home .landing-cutout {
  border-radius: 2px;
  padding: 15px 30px 0;
  width: 500px;
  height: 200px;
  display: flex;
  flex-direction: column;
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.5);
  background: #f2f2f2;
  color: #1a1a1a;
  overflow: hidden;
  position: absolute;
  margin-left: 501px;
}
@media (max-width: 1200px) {
  #home .landing-cutout {
    width: 400px;
    margin-left: 450px;
  }
}
@media (max-width: 900px) {
  #home .landing-cutout {
    height: 150px;
    width: 270px;
    margin-left: 320px;
    padding: 10px 0 0;
  }
}
@media (max-width: 600px) {
  #home .landing-cutout {
    height: 150px;
    width: 270px;
    margin: 120px 0 0 40px;
    padding: 10px 0 0;
  }
}
#home .landing-cutout .parallax-layer--back {
  -webkit-transform: translateZ(-0.08px) scale(1.08);
  transform: translateZ(-0.08px) scale(1.08);
  z-index: -1;
  z-index: 0;
  text-align: center;
}
#home .landing-cutout .parallax-layer--back .name {
  font-family: 'Staatliches', cursive;
  font-size: 60px;
}
@media (max-width: 900px) {
  #home .landing-cutout .parallax-layer--back .name {
    font-size: 37px;
  }
}
#home .landing-cutout .parallax-layer--back .title {
  font-size: 27.8px;
}
@media (max-width: 900px) {
  #home .landing-cutout .parallax-layer--back .title {
    font-size: 17.2px;
  }
}
#home .landing-cutout .learn-more {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
#home .landing-cutout .learn-more .tab {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  width: 180px;
  height: 50px;
  padding: 10px;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  text-decoration: none;
  color: #1a1a1a;
}
@media (max-width: 1200px) {
  #home .landing-cutout .learn-more .tab {
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
    margin-bottom: -6px;
  }
}
#home .landing-cutout .learn-more .tab .text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #f2f2f2;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  border-radius: 2px;
}
#home .landing-cutout .learn-more .tab .text img {
  height: 20px;
}
#about {
  width: 100%;
  margin-bottom: 200px;
}
@media (max-width: 600px) {
  #about {
    margin-bottom: 100px;
  }
}
#about #about-banner {
  width: 100%;
  display: flex;
  align-items: center;
}
#about #about-banner .bars-container-1 {
  height: 130px;
  min-width: 328px;
  max-width: 328px;
  overflow: hidden;
}
@media (max-width: 1200px) {
  #about #about-banner .bars-container-1 {
    margin-left: -160px;
  }
}
@media (max-width: 600px) {
  #about #about-banner .bars-container-1 {
    height: 100px;
    min-width: 290px;
    max-width: 290px;
  }
}
#about #about-banner .bars-container-1 .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #about #about-banner .bars-container-1 .base {
    margin: -200px 0 0 -200px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#about #about-banner .cyan-bars-container {
  height: 130px;
  min-width: 294px;
  max-width: 294px;
  overflow: hidden;
}
@media (max-width: 600px) {
  #about #about-banner .cyan-bars-container {
    height: 100px;
    min-width: 163px;
    max-width: 163px;
  }
}
#about #about-banner .cyan-bars-container .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #about #about-banner .cyan-bars-container .base {
    margin: -200px 0 0 -300px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#about #about-banner .bars-container-2 {
  height: 130px;
  overflow: hidden;
}
@media (max-width: 600px) {
  #about #about-banner .bars-container-2 {
    height: 100px;
    min-width: 290px;
    max-width: 290px;
  }
}
#about #about-banner .bars-container-2 .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #about #about-banner .bars-container-2 .base {
    margin: -200px 0 0 -300px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#about #about-banner .parallax__layer--scanimation {
  -webkit-transform: translateZ(-0.07px) scale(1.07);
  transform: translateZ(-0.07px) scale(1.07);
  margin: -20px 0 0 150px;
  position: absolute;
  z-index: -1;
}
@media (max-width: 1200px) {
  #about #about-banner .parallax__layer--scanimation {
    margin-left: 0px;
  }
}
@media (max-width: 600px) {
  #about #about-banner .parallax__layer--scanimation {
    -webkit-transform: translateZ(-0.07px) scale(1.07);
    transform: translateZ(-0.056px) scale(0.856);
  }
}
#about #about-banner .about-sticker {
  -webkit-transform: translateZ(0.02px) scale(0.98);
  transform: translateZ(0.02px) scale(0.98);
  z-index: 1;
  border-radius: 2px;
  padding: 5px;
  width: 180px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  background: #f2f2f2;
  color: #1a1a1a;
  overflow: hidden;
  position: absolute;
  margin-left: 390px;
}
@media (max-width: 1200px) {
  #about #about-banner .about-sticker {
    margin-left: 225px;
  }
}
@media (max-width: 600px) {
  #about #about-banner .about-sticker {
    width: 120px;
    height: 60px;
    margin-left: 150px;
  }
}
#about #about-banner .about-sticker .parallax-layer--front {
  text-align: center;
}
#about #about-banner .about-sticker .parallax-layer--front .name {
  font-family: 'Staatliches', cursive;
  font-size: 60px;
}
@media (max-width: 600px) {
  #about #about-banner .about-sticker .parallax-layer--front .name {
    font-size: 40px;
  }
}
#about .about-content-container {
  width: 100%;
  justify-content: center;
  display: flex;
}
#about .about-content-container .about-content-cutout {
  box-shadow: inset 5px 5px 8px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  display: flex;
  height: 300px;
  margin: 80px;
  padding: 40px;
  overflow: hidden;
  margin-left: 200px;
}
@media (max-width: 1200px) {
  #about .about-content-container .about-content-cutout {
    margin-left: 80px;
  }
}
@media (max-width: 900px) {
  #about .about-content-container .about-content-cutout {
    height: 200px;
    margin: 50px 20px 0;
    padding: 20px;
  }
}
@media (max-width: 600px) {
  #about .about-content-container .about-content-cutout {
    height: auto;
    width: 350px;
    margin: 50px 20px 0;
    padding: 20px;
    flex-flow: column;
    align-items: center;
  }
}
#about .about-content-container .about-content-cutout img {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
  z-index: 1;
  width: auto;
  max-width: 219px;
  height: 100%;
  border-radius: 2px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
@media (max-width: 900px) {
  #about .about-content-container .about-content-cutout img {
    width: auto;
    max-width: 219px;
  }
}
@media (max-width: 600px) {
  #about .about-content-container .about-content-cutout img {
    width: 100%;
    height: auto;
    max-height: 219px;
  }
}
#about .about-content-container .about-content-cutout .about-and-skills {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-left: 20px;
}
@media (max-width: 600px) {
  #about .about-content-container .about-content-cutout .about-and-skills {
    margin: 0;
  }
}
#about .about-content-container .about-content-cutout .about-and-skills .about-me {
  -webkit-transform: translateZ(-0.08px) scale(1.08);
  transform: translateZ(-0.08px) scale(1.08);
  z-index: -1;
  font-size: 1.8rem;
  margin: -10px 0 0 30px;
  color: #1a1a1a;
}
@media (max-width: 900px) {
  #about .about-content-container .about-content-cutout .about-and-skills .about-me {
    font-size: 1.5rem;
    margin: -10px 10px 0;
  }
}
@media (max-width: 600px) {
  #about .about-content-container .about-content-cutout .about-and-skills .about-me {
    font-size: 1.3rem;
    margin: 20px 0px 10px;
  }
}
#about .about-content-container .about-content-cutout .about-and-skills .about-me span {
  font-weight: bold;
  color: #0099dd;
}
#about .about-content-container .about-content-cutout .about-and-skills .skills-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}
#about .about-content-container .about-content-cutout .about-and-skills .skills-container .skill {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background: #f2f2f2;
  margin: 9px;
  padding: 10px;
  color: #0099dd;
  font-weight: bold;
  font-size: 1.6rem;
}
@media (max-width: 900px) {
  #about .about-content-container .about-content-cutout .about-and-skills .skills-container .skill {
    font-size: 1.3rem;
    margin: 5px;
    padding: 7px;
  }
}
#projects {
  width: 100%;
  margin-bottom: 200px;
}
@media (max-width: 600px) {
  #projects {
    margin-bottom: 100px;
  }
}
#projects #projects-banner {
  width: 100%;
  display: flex;
  align-items: center;
}
#projects #projects-banner .bars-container-1 {
  height: 130px;
  min-width: 328px;
  max-width: 328px;
  overflow: hidden;
}
@media (max-width: 1200px) {
  #projects #projects-banner .bars-container-1 {
    margin-left: -160px;
  }
}
@media (max-width: 600px) {
  #projects #projects-banner .bars-container-1 {
    height: 100px;
    min-width: 290px;
    max-width: 290px;
  }
}
#projects #projects-banner .bars-container-1 .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #projects #projects-banner .bars-container-1 .base {
    margin: -200px 0 0 -200px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#projects #projects-banner .magenta-bars-container {
  height: 130px;
  min-width: 373px;
  max-width: 373px;
  overflow: hidden;
}
@media (max-width: 600px) {
  #projects #projects-banner .magenta-bars-container {
    height: 100px;
    min-width: 199px;
    max-width: 199px;
  }
}
#projects #projects-banner .magenta-bars-container .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #projects #projects-banner .magenta-bars-container .base {
    margin: -200px 0 0 -300px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#projects #projects-banner .bars-container-2 {
  height: 130px;
  overflow: hidden;
}
@media (max-width: 600px) {
  #projects #projects-banner .bars-container-2 {
    height: 100px;
    min-width: 290px;
    max-width: 290px;
  }
}
#projects #projects-banner .bars-container-2 .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #projects #projects-banner .bars-container-2 .base {
    margin: -200px 0 0 -300px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#projects #projects-banner .parallax__layer--scanimation {
  -webkit-transform: translateZ(-0.07px) scale(1.07);
  transform: translateZ(-0.07px) scale(1.07);
  margin: -20px 0 0 150px;
  position: absolute;
  z-index: -1;
}
@media (max-width: 1200px) {
  #projects #projects-banner .parallax__layer--scanimation {
    margin-left: 0px;
  }
}
@media (max-width: 600px) {
  #projects #projects-banner .parallax__layer--scanimation {
    -webkit-transform: translateZ(-0.07px) scale(1.07);
    transform: translateZ(-0.056px) scale(0.856);
  }
}
#projects #projects-banner .projects-sticker {
  -webkit-transform: translateZ(0.02px) scale(0.98);
  transform: translateZ(0.02px) scale(0.98);
  z-index: 1;
  border-radius: 2px;
  padding: 5px;
  width: 250px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  background: #f2f2f2;
  color: #1a1a1a;
  overflow: hidden;
  position: absolute;
  margin-left: 390px;
}
@media (max-width: 1200px) {
  #projects #projects-banner .projects-sticker {
    margin-left: 225px;
  }
}
@media (max-width: 600px) {
  #projects #projects-banner .projects-sticker {
    width: 160px;
    height: 60px;
    margin-left: 150px;
  }
}
#projects #projects-banner .projects-sticker .parallax-layer--front {
  text-align: center;
}
#projects #projects-banner .projects-sticker .parallax-layer--front .name {
  font-family: 'Staatliches', cursive;
  font-size: 60px;
}
@media (max-width: 600px) {
  #projects #projects-banner .projects-sticker .parallax-layer--front .name {
    font-size: 40px;
  }
}
#projects .projects-content-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
#projects .projects-content-container .project-cutout {
  box-shadow: inset 5px 5px 8px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  height: 300px;
  display: flex;
  margin: 80px 80px 0 200px;
  padding: 40px;
  overflow: hidden;
  position: relative;
  align-items: center;
}
@media (max-width: 1200px) {
  #projects .projects-content-container .project-cutout {
    margin-left: 80px;
  }
}
@media (max-width: 900px) {
  #projects .projects-content-container .project-cutout {
    margin: 50px 20px 0;
    padding: 40px 17px;
    height: 250px;
  }
}
@media (max-width: 600px) {
  #projects .projects-content-container .project-cutout {
    height: auto;
    margin: 50px 10px 0px;
    padding: 10px 10px 0px;
    flex-flow: column;
    align-items: center;
  }
}
#projects .projects-content-container .project-cutout .project-image {
  -webkit-transform: translateZ(-0.08px) scale(1.08);
  transform: translateZ(-0.08px) scale(1.08);
  z-index: -1;
  height: 100%;
  width: auto;
}
@media (max-width: 900px) {
  #projects .projects-content-container .project-cutout .project-image {
    min-width: 230px;
    max-width: 230px;
    height: 149px;
  }
}
@media (max-width: 600px) {
  #projects .projects-content-container .project-cutout .project-image {
    width: 80%;
    max-width: 230px;
    height: auto;
    margin-top: 10px;
  }
}
#projects .projects-content-container .project-cutout .text {
  -webkit-transform: translateZ(-0.08px) scale(1.08);
  transform: translateZ(-0.08px) scale(1.08);
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0 10px 80px;
  color: #1a1a1a;
}
@media (max-width: 900px) {
  #projects .projects-content-container .project-cutout .text {
    margin-right: 6px;
  }
}
@media (max-width: 600px) {
  #projects .projects-content-container .project-cutout .text {
    width: 90%;
    margin: 20px;
  }
}
#projects .projects-content-container .project-cutout .text .title {
  font-size: 2rem;
  font-weight: bold;
}
@media (max-width: 900px) {
  #projects .projects-content-container .project-cutout .text .title {
    font-size: 1.6rem;
  }
}
@media (max-width: 600px) {
  #projects .projects-content-container .project-cutout .text .title {
    margin-top: 10px;
  }
}
#projects .projects-content-container .project-cutout .text .description {
  font-size: 1.5rem;
  margin-top: 15px;
}
@media (max-width: 900px) {
  #projects .projects-content-container .project-cutout .text .description {
    font-size: 1.3rem;
  }
}
#projects .projects-content-container .project-cutout .text .tools {
  font-style: italic;
  font-size: 1.2rem;
}
@media (max-width: 900px) {
  #projects .projects-content-container .project-cutout .text .tools {
    font-size: 1rem;
  }
}
@media (max-width: 600px) {
  #projects .projects-content-container .project-cutout .text .tools {
    margin-top: 30px;
  }
}
#projects .projects-content-container .project-cutout .links {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
  position: absolute;
  top: 0px;
  right: 20px;
  display: flex;
}
@media (max-width: 900px) {
  #projects .projects-content-container .project-cutout .links {
    right: 0;
  }
}
#projects .projects-content-container .project-cutout .links .link {
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  background: #f2f2f2;
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 20px 10px 10px;
  margin-right: 20px;
  width: 100%;
  display: flex;
  text-decoration: none;
}
@media (max-width: 900px) {
  #projects .projects-content-container .project-cutout .links .link {
    padding: 10px;
  }
}
#projects .projects-content-container .project-cutout .links .link .link-text {
  z-index: 1;
  text-align: center;
  color: #1a1a1a;
  font-size: 1.3rem;
  font-weight: bold;
  border-bottom: solid 3px #d90d79;
  width: 100%;
  display: flex;
  align-items: center;
}
#projects .projects-content-container .project-cutout .links .link .link-text img {
  z-index: 1;
  height: 10px;
  transform: rotate(-90deg);
  margin-left: 5px;
}
#articles {
  width: 100%;
  margin-bottom: 200px;
}
@media (max-width: 600px) {
  #articles {
    margin-bottom: 100px;
  }
}
#articles #articles-banner {
  width: 100%;
  display: flex;
  align-items: center;
}
#articles #articles-banner .bars-container-1 {
  height: 130px;
  min-width: 328px;
  max-width: 328px;
  overflow: hidden;
}
@media (max-width: 1200px) {
  #articles #articles-banner .bars-container-1 {
    margin-left: -160px;
  }
}
@media (max-width: 600px) {
  #articles #articles-banner .bars-container-1 {
    height: 100px;
    min-width: 290px;
    max-width: 290px;
  }
}
#articles #articles-banner .bars-container-1 .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #articles #articles-banner .bars-container-1 .base {
    margin: -200px 0 0 -200px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#articles #articles-banner .yellow-bars-container {
  height: 130px;
  min-width: 351px;
  max-width: 351px;
  overflow: hidden;
}
@media (max-width: 600px) {
  #articles #articles-banner .yellow-bars-container {
    height: 100px;
    min-width: 190px;
    max-width: 190px;
  }
}
#articles #articles-banner .yellow-bars-container .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #articles #articles-banner .yellow-bars-container .base {
    margin: -200px 0 0 -300px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#articles #articles-banner .bars-container-2 {
  height: 130px;
  overflow: hidden;
}
@media (max-width: 600px) {
  #articles #articles-banner .bars-container-2 {
    height: 100px;
    min-width: 290px;
    max-width: 290px;
  }
}
#articles #articles-banner .bars-container-2 .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #articles #articles-banner .bars-container-2 .base {
    margin: -200px 0 0 -300px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#articles #articles-banner .parallax__layer--scanimation {
  -webkit-transform: translateZ(-0.07px) scale(1.07);
  transform: translateZ(-0.07px) scale(1.07);
  margin: -20px 0 0 150px;
  position: absolute;
  z-index: -1;
}
@media (max-width: 1200px) {
  #articles #articles-banner .parallax__layer--scanimation {
    margin-left: 0px;
  }
}
@media (max-width: 600px) {
  #articles #articles-banner .parallax__layer--scanimation {
    -webkit-transform: translateZ(-0.07px) scale(1.07);
    transform: translateZ(-0.056px) scale(0.856);
  }
}
#articles #articles-banner .articles-sticker {
  -webkit-transform: translateZ(0.02px) scale(0.98);
  transform: translateZ(0.02px) scale(0.98);
  z-index: 1;
  border-radius: 2px;
  padding: 5px;
  width: 230px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  background: #f2f2f2;
  color: #1a1a1a;
  overflow: hidden;
  position: absolute;
  margin-left: 390px;
}
@media (max-width: 1200px) {
  #articles #articles-banner .articles-sticker {
    margin-left: 225px;
  }
}
@media (max-width: 600px) {
  #articles #articles-banner .articles-sticker {
    width: 145px;
    height: 60px;
    margin-left: 150px;
  }
}
#articles #articles-banner .articles-sticker .parallax-layer--front {
  text-align: center;
}
#articles #articles-banner .articles-sticker .parallax-layer--front .name {
  font-family: 'Staatliches', cursive;
  font-size: 60px;
}
@media (max-width: 600px) {
  #articles #articles-banner .articles-sticker .parallax-layer--front .name {
    font-size: 40px;
  }
}
#articles .articles-content-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding-left: 160px;
}
@media (max-width: 1200px) {
  #articles .articles-content-container {
    padding-left: 0;
  }
}
#articles .articles-content-container .article-sticker {
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  background: #f2f2f2;
  border-radius: 2px;
  -webkit-transform: translateZ(0.02px) scale(0.98);
  transform: translateZ(0.02px) scale(0.98);
  z-index: 1;
  width: 100%;
  max-width: 350px;
  display: flex;
  margin: 80px 20px 20px;
  position: relative;
  align-items: center;
  flex-flow: column;
}
@media (max-width: 600px) {
  #articles .articles-content-container .article-sticker {
    margin: 50px 10px 40px;
  }
}
#articles .articles-content-container .article-sticker .article-image {
  width: 100%;
  height: 200px;
  background-size: cover;
}
#articles .articles-content-container .article-sticker .text {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  color: #1a1a1a;
}
#articles .articles-content-container .article-sticker .text .title {
  font-size: 2rem;
  font-weight: bold;
}
#articles .articles-content-container .article-sticker .text .description {
  font-size: 1.4rem;
  margin-top: 15px;
}
#articles .articles-content-container .article-sticker .text .date {
  font-style: italic;
  font-size: 1.2rem;
  margin-top: 30px;
}
#articles .articles-content-container .article-sticker .links {
  position: absolute;
  bottom: -46px;
  right: 0px;
  display: flex;
  z-index: -1;
  clip: rect(0px 100px 100px 0px);
}
#articles .articles-content-container .article-sticker .links .link {
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  background: #f2f2f2;
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 20px 10px 10px;
  width: 100%;
  display: flex;
  text-decoration: none;
}
#articles .articles-content-container .article-sticker .links .link .link-text {
  z-index: 1;
  text-align: center;
  color: #1a1a1a;
  font-size: 1.3rem;
  font-weight: bold;
  border-bottom: solid 3px #ffdd00;
  width: 100%;
  display: flex;
  align-items: center;
}
#articles .articles-content-container .article-sticker .links .link .link-text img {
  z-index: 1;
  height: 10px;
  transform: rotate(-90deg);
  margin-left: 5px;
}
#resume {
  width: 100%;
  margin-bottom: 200px;
}
@media (max-width: 600px) {
  #resume {
    margin-bottom: 100px;
  }
}
#resume #resume-banner {
  width: 100%;
  display: flex;
  align-items: center;
}
#resume #resume-banner .bars-container-1 {
  height: 130px;
  min-width: 328px;
  max-width: 328px;
  overflow: hidden;
}
@media (max-width: 1200px) {
  #resume #resume-banner .bars-container-1 {
    margin-left: -160px;
  }
}
@media (max-width: 600px) {
  #resume #resume-banner .bars-container-1 {
    height: 100px;
    min-width: 290px;
    max-width: 290px;
  }
}
#resume #resume-banner .bars-container-1 .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #resume #resume-banner .bars-container-1 .base {
    margin: -200px 0 0 -200px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#resume #resume-banner .cyan-bars-container {
  height: 130px;
  min-width: 317px;
  max-width: 317px;
  overflow: hidden;
}
@media (max-width: 600px) {
  #resume #resume-banner .cyan-bars-container {
    height: 100px;
    min-width: 172px;
    max-width: 172px;
  }
}
#resume #resume-banner .cyan-bars-container .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #resume #resume-banner .cyan-bars-container .base {
    margin: -200px 0 0 -300px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#resume #resume-banner .bars-container-2 {
  height: 130px;
  overflow: hidden;
}
@media (max-width: 600px) {
  #resume #resume-banner .bars-container-2 {
    height: 100px;
    min-width: 290px;
    max-width: 290px;
  }
}
#resume #resume-banner .bars-container-2 .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #resume #resume-banner .bars-container-2 .base {
    margin: -200px 0 0 -300px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#resume #resume-banner .parallax__layer--scanimation {
  -webkit-transform: translateZ(-0.07px) scale(1.07);
  transform: translateZ(-0.07px) scale(1.07);
  margin: -20px 0 0 150px;
  position: absolute;
  z-index: -1;
}
@media (max-width: 1200px) {
  #resume #resume-banner .parallax__layer--scanimation {
    margin-left: 0px;
  }
}
@media (max-width: 600px) {
  #resume #resume-banner .parallax__layer--scanimation {
    -webkit-transform: translateZ(-0.07px) scale(1.07);
    transform: translateZ(-0.056px) scale(0.856);
  }
}
#resume #resume-banner .resume-sticker {
  -webkit-transform: translateZ(0.02px) scale(0.98);
  transform: translateZ(0.02px) scale(0.98);
  z-index: 1;
  border-radius: 2px;
  padding: 5px;
  width: 200px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  background: #f2f2f2;
  color: #1a1a1a;
  overflow: hidden;
  position: absolute;
  margin-left: 390px;
}
@media (max-width: 1200px) {
  #resume #resume-banner .resume-sticker {
    margin-left: 225px;
  }
}
@media (max-width: 600px) {
  #resume #resume-banner .resume-sticker {
    width: 130px;
    height: 60px;
    margin-left: 150px;
  }
}
#resume #resume-banner .resume-sticker .parallax-layer--front {
  text-align: center;
}
#resume #resume-banner .resume-sticker .parallax-layer--front .name {
  font-family: 'Staatliches', cursive;
  font-size: 60px;
}
@media (max-width: 600px) {
  #resume #resume-banner .resume-sticker .parallax-layer--front .name {
    font-size: 40px;
  }
}
#resume .resume-content-container {
  width: 100%;
  justify-content: center;
  display: flex;
}
#resume .resume-content-container .resume-content-cutout {
  box-shadow: inset 5px 5px 8px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  margin: 80px;
  padding: 40px;
  overflow: hidden;
  position: relative;
  margin-left: 200px;
}
@media (max-width: 1200px) {
  #resume .resume-content-container .resume-content-cutout {
    margin-left: 80px;
  }
}
@media (max-width: 900px) {
  #resume .resume-content-container .resume-content-cutout {
    margin: 50px 20px 0;
    padding: 20px;
  }
}
@media (max-width: 600px) {
  #resume .resume-content-container .resume-content-cutout {
    height: auto;
    width: 350px;
    margin: 50px 20px 0;
    padding: 20px;
    flex-flow: column;
    align-items: center;
  }
}
#resume .resume-content-container .resume-content-cutout .resume {
  width: 100%;
  height: auto;
}
#resume .resume-content-container .resume-content-cutout .links {
  position: absolute;
  top: 0px;
  right: 20px;
  display: flex;
  z-index: 1;
}
#resume .resume-content-container .resume-content-cutout .links .link {
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  background: #f2f2f2;
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 20px 10px 10px;
  width: 100%;
  display: flex;
  text-decoration: none;
}
#resume .resume-content-container .resume-content-cutout .links .link .link-text {
  z-index: 1;
  text-align: center;
  color: #1a1a1a;
  font-size: 1.3rem;
  font-weight: bold;
  border-bottom: solid 3px #0099dd;
  width: 100%;
  display: flex;
  align-items: center;
}
#resume .resume-content-container .resume-content-cutout .links .link .link-text img {
  z-index: 1;
  height: 10px;
  width: auto;
  margin-left: 5px;
}
#contact {
  width: 100%;
  height: 100vh;
}
@media (max-width: 600px) {
  #contact {
    margin-bottom: 100px;
  }
}
#contact #contact-banner {
  width: 100%;
  display: flex;
  align-items: center;
}
#contact #contact-banner .bars-container-1 {
  height: 130px;
  min-width: 328px;
  max-width: 328px;
  overflow: hidden;
}
@media (max-width: 1200px) {
  #contact #contact-banner .bars-container-1 {
    margin-left: -160px;
  }
}
@media (max-width: 600px) {
  #contact #contact-banner .bars-container-1 {
    height: 100px;
    min-width: 290px;
    max-width: 290px;
  }
}
#contact #contact-banner .bars-container-1 .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #contact #contact-banner .bars-container-1 .base {
    margin: -200px 0 0 -200px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#contact #contact-banner .magenta-bars-container {
  height: 130px;
  min-width: 351px;
  max-width: 351px;
  overflow: hidden;
}
@media (max-width: 600px) {
  #contact #contact-banner .magenta-bars-container {
    height: 100px;
    min-width: 190px;
    max-width: 190px;
  }
}
#contact #contact-banner .magenta-bars-container .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #contact #contact-banner .magenta-bars-container .base {
    margin: -200px 0 0 -300px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#contact #contact-banner .bars-container-2 {
  height: 130px;
  overflow: hidden;
}
@media (max-width: 600px) {
  #contact #contact-banner .bars-container-2 {
    height: 100px;
    min-width: 290px;
    max-width: 290px;
  }
}
#contact #contact-banner .bars-container-2 .base {
  -webkit-transform: translateZ(0px) scale(1);
  transform: translateZ(0px) scale(1);
}
@media (max-width: 600px) {
  #contact #contact-banner .bars-container-2 .base {
    margin: -200px 0 0 -300px;
    -webkit-transform: translateZ(0px) scale(0.8);
    transform: translateZ(0px) scale(0.8);
  }
}
#contact #contact-banner .parallax__layer--scanimation {
  -webkit-transform: translateZ(-0.07px) scale(1.07);
  transform: translateZ(-0.07px) scale(1.07);
  margin: -20px 0 0 150px;
  position: absolute;
  z-index: -1;
}
@media (max-width: 1200px) {
  #contact #contact-banner .parallax__layer--scanimation {
    margin-left: 0px;
  }
}
@media (max-width: 600px) {
  #contact #contact-banner .parallax__layer--scanimation {
    -webkit-transform: translateZ(-0.07px) scale(1.07);
    transform: translateZ(-0.056px) scale(0.856);
  }
}
#contact #contact-banner .contact-sticker {
  -webkit-transform: translateZ(0.02px) scale(0.98);
  transform: translateZ(0.02px) scale(0.98);
  z-index: 1;
  border-radius: 2px;
  padding: 5px;
  width: 230px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  background: #f2f2f2;
  color: #1a1a1a;
  overflow: hidden;
  position: absolute;
  margin-left: 390px;
}
@media (max-width: 1200px) {
  #contact #contact-banner .contact-sticker {
    margin-left: 225px;
  }
}
@media (max-width: 600px) {
  #contact #contact-banner .contact-sticker {
    width: 145px;
    height: 60px;
    margin-left: 150px;
  }
}
#contact #contact-banner .contact-sticker .parallax-layer--front {
  text-align: center;
}
#contact #contact-banner .contact-sticker .parallax-layer--front .name {
  font-family: 'Staatliches', cursive;
  font-size: 60px;
}
@media (max-width: 600px) {
  #contact #contact-banner .contact-sticker .parallax-layer--front .name {
    font-size: 40px;
  }
}
#contact .contacts-content-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding-left: 160px;
  margin-bottom: 50px;
}
@media (max-width: 1200px) {
  #contact .contacts-content-container {
    padding-left: 0;
  }
}
#contact .contacts-content-container .contact-sticker {
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  background: #f2f2f2;
  border-radius: 2px;
  -webkit-transform: translateZ(0.02px) scale(0.98);
  transform: translateZ(0.02px) scale(0.98);
  z-index: 1;
  width: 100%;
  max-width: 300px;
  display: flex;
  margin: 80px 20px 20px;
  position: relative;
  align-items: center;
  padding: 20px;
  text-decoration: none;
  color: #1a1a1a;
}
@media (max-width: 600px) {
  #contact .contacts-content-container .contact-sticker {
    margin: 50px 10px 40px;
  }
}
#contact .contacts-content-container .contact-sticker .contact-image {
  height: 100%;
  max-height: 50px;
  width: auto;
}
#contact .contacts-content-container .contact-sticker .text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}
#contact .contacts-content-container .contact-sticker .text .title {
  font-size: 2rem;
  font-weight: bold;
}
#contact .contacts-content-container .contact-sticker .text .description {
  font-size: 1.4rem;
}
#contact .contacts-content-container .contact-sticker .text .date {
  font-style: italic;
  font-size: 1.2rem;
  margin-top: 30px;
}
#contact .contacts-content-container .contact-sticker .links {
  position: absolute;
  bottom: -46px;
  right: 0px;
  display: flex;
  z-index: -1;
  clip: rect(0px 100px 100px 0px);
}
#contact .contacts-content-container .contact-sticker .links .link {
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  background: #f2f2f2;
  border-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 20px 10px 10px;
  width: 100%;
  display: flex;
  text-decoration: none;
}
#contact .contacts-content-container .contact-sticker .links .link .link-text {
  z-index: 1;
  text-align: center;
  color: #1a1a1a;
  font-size: 1.3rem;
  font-weight: bold;
  border-bottom: solid 3px #d90d79;
  width: 100%;
  display: flex;
  align-items: center;
}
#contact .contacts-content-container .contact-sticker .links .link .link-text img {
  z-index: 1;
  height: 10px;
  transform: rotate(-90deg);
  margin-left: 5px;
}
